<template>
  <PrimaryMenu />

  <Header title="404" />

  <SectionBlock class="pb-0">
    <SectionTitle title="A keresett oldal nem található!" />
  </SectionBlock>

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionBlock from "@/components/layout/sections/parts/SectionBlock";
import SectionTitle from "@/components/layout/sections/parts/SectionTitle";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";

export default {
  name: "Not Found",
  title: "404 A keresett oldal nem található!",
  components: {
    PrimaryMenu,
    Header,
    SectionBlock,
    SectionTitle,
    SectionContact,
    Footer,
  },
};
</script>

<style lang="scss"></style>
